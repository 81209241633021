<template>
  <v-container fluid class="pa-0 ma-0" >
             <v-img   :lazy-src="require('../assets/background.jpg')" :src="require('../assets/background.jpg')"  gradient="rgba(100,115,201,.33), rgba(25,32,72,.7)" >



  <v-layout row wrap :style="{'padding-top': '50px'}" class="ma-3">
  <v-card :style="{'background-color':'#0a0d387d'}" class="mx-auto text-center mb-5 pa-2"  >
<h2 class="white--text" >O-Run sera disponible très prochainement ! </h2>
  
  <h2 class="white--text">Pour être informé de la disponibilité veuillez laisser votre email.</h2>
<h3 class="white--text">Nous finalisons les derniers détails afin de vous proposer une application de qualité.</h3>

  </v-card>
 


  

 
  </v-layout>
  <v-row wrap :style="{'padding-top': '50px'}" class="ma-3">
    <v-card class="text-center mb-5 pa-2 mt-5" cols="6"  >
      <v-card-title >Découvrir le "manager" : pour créer et gérer ses évènements.</v-card-title>
      <v-card-text class="white--text">
       
      </v-card-text>
    </v-card>

  <v-card class="text-center mb-5 pa-2 mt-5" >
      <v-card-title >Découvrir l'application </v-card-title>
      <v-card-text >
       
      </v-card-text>
    </v-card>
  </v-row>


          
          

      
 </v-img>
  </v-container>
</template>
<script>


export default {
  data: () => ({
    form: {name: '', email: ''},
    sent: false,
    loading: false
  }),
  computed: {
    formOK() {
      return this.form.name.length > 2 && this.form.email.length > 2 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email);
    }
  },
  methods: {

  }
}
</script>


